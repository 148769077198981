import Typed from 'typed.js'

const element = document.getElementById('typed')
element.innerHTML = ''
element.typed = new Typed(element, {
  strings: ['fikirler', 'eğitimler', 'süreçler'],
  typeSpeed: 60,
  backSpeed: 30,
  backDelay: 1000,
  loop: true,
  smartBackspace: false
})
